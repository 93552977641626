export const setSwipeView = (value) => ({
  type: 'SET_MY_VARIABLE',
  payload: value,
});

export const setScreenWidth = (value) => ({
  type: 'SET_SCREEN_WIDTH',
  payload: value,
});

export const setTokensData = (value) => ({
  type: 'SET_TOKENS_DATA',
  payload: value,
});

export const setSuggestionData = (value) => ({
  type: 'SET_SUGGESTION_DATA',
  payload: value,
});