import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

import swipeViewReducer from './scanmeme/reducer'; // import reducer'a

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  swipeViewState: swipeViewReducer,
  screenWidthState: swipeViewReducer,
  tokensData: swipeViewReducer,
  suggestionData: swipeViewReducer,
});

export default rootReducer;
