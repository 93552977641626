import React, { useState } from 'react';
import moment from "moment";
import './style/searchWithAutocomplete.css';
import Chart from "pages/Bondinglive/Chart";
import copy from '../../pages/Bondinglive/img/copy.svg'
import tweeterSVG from '../../pages/Bondinglive/img/tweeter.svg'
import telegramSVG from '../../pages/Bondinglive/img/telegram.svg'
import wwwSVG from '../../pages/Bondinglive/img/web.svg'
import toast, { Toaster } from 'react-hot-toast';
import TableContainer from 'components/Common/TableContainer';
import { createColumnHelper } from '@tanstack/react-table';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import { SiDevdotto } from "react-icons/si";
import { defaultTheme } from "react-select";

const TokenModalContent = ({
  tokenDetail,
  accessTier
}) => {
  const shouldShow = accessTier;
  const columnHelper = createColumnHelper();
  const columns_holders_list = [
    columnHelper.accessor(row => row.amount, {
      id: 'amount',
      cell: info => {
        return (
          <div>
            {formatNumberPrice(info.cell.row.original.amount)}
          </div>
        )
      },
      header: () => <span>Amount</span>,
      enableColumnFilter: false      
    }),
    columnHelper.accessor(row => row.amount_percent, {
      id: 'amount_percent',
      cell: info => {
        return (
          <div className='to-right'>
            {parseFloat(info.cell.row.original.amount_percent).toFixed(2)}%
          </div>

        )
      },
      header: () => <span style={{ textAlign: 'right', display: 'block' }}>Percent</span>,
      enableColumnFilter: false      
    }),
    columnHelper.accessor(row => row.wallet_address, {
      id: 'wallet_address',
      cell: info => {
        return (
          <div className='to-right'>
            
            {info.cell.row.original.creator 
              ? <span data-tooltip-id="dev" data-tooltip-content={'Token Creator'}><SiDevdotto size={30} color={'#2d9863'} style={{marginRight: '10px'}}/> </span>
              : ''
            }
            <a 
              className="text-dark"
              href={`https://tronscan.org/#/address/${info.cell.row.original.wallet_address}`} 
              target="_blank" 
              rel="noopener noreferrer"
              style={{color: defaultTheme.colors.primary, cursor: 'pointer'}}
            >
              {info.cell.row.original.wallet_address}
            </a>
            <Tooltip
              id="dev"
              place={'top'}
            />
          </div>
        )
      },
      header: () => <span style={{ textAlign: 'right', display: 'block' }}>Address</span>,
      enableColumnFilter: false      
    }),
  ];

  const transformArray = (inputArray) => {
    const transformedArray = inputArray.map(item => {
        return {
            time: moment(item[0]).valueOf() / 1000,
            value: parseFloat(item[1])
        };
    });
    transformedArray.sort((a, b) => a.time - b.time);
    const uniqueArray = transformedArray.filter((item, index, self) => {
        return index === 0 || item.time !== self[index - 1].time;
    });
    return uniqueArray;
  };

  const copyToClipboard = (address) => {
    navigator.clipboard.writeText(address)
      .then(() => {
        toast.success('Copied!', {
          // icon: <MdOutlineAddCircleOutline />,
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
      })
      .catch((err) => {
        console.error('Error while copying: ', err);
      });
  };
  
  const handleRedirect = (url) => {
    window.open(url, '_blank');  
  };

  const formatString = (str) => {
    const start = str.slice(0, 4); // Pierwsze 4 znaki
    const end = str.slice(-4); // Ostatnie 4 znaki
    return `${start}...${end}`;
  };

  function secondsToTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const hoursString = hours.toString().padStart(2, '0');
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const minutesString = minutes.toString().padStart(2, '0');
    const remainingSecondsFinal = remainingSeconds % 60;
    const secondsString = remainingSecondsFinal.toFixed(0).padStart(2, '0');
  
    return `${hoursString}:${minutesString}:${secondsString}`;
  };

  function addHolderParameter(coin) {
    
    function sortAndGetTop1(holdersList) {
      return holdersList
        .sort((a, b) => parseFloat(b.amount_percent) - parseFloat(a.amount_percent))
        .slice(0, 1);
    };

    if(coin){
      const holdersList = coin.data.holders_list;
      const topHolder = sortAndGetTop1(holdersList);
      const holder = topHolder.length > 0 ? parseFloat(topHolder[0].amount_percent).toFixed(2) : '0.00';
      return {
        ...coin,
        data: {
          ...coin.data,
          holder: holder
        }
      };   
    }
  };

  const holderTopColor = (holder) => {
    let color = ''
    let fontColor = ''

    const procentHolde = holder
    const procentHoldeInt = procentHolde ? parseFloat(procentHolde).toFixed(0) : null

    if(procentHoldeInt === null){
      color = "transparent"
    }else if(procentHoldeInt <= 5){
      color = '#479a70';
      fontColor = '#ffffff'
    }else if(procentHoldeInt > 5 && procentHoldeInt <= 10){
      color = '#d8b744';
      fontColor = '#000000'
    }else if(procentHoldeInt > 10 && procentHoldeInt <= 20){
      color = '#da7124';
      fontColor = '#000000'
    }else if(procentHoldeInt > 20){
      color = '#dc1f1d';
      fontColor = '#ffffff'
    }
    return { color, fontColor }
  };

  const devColor = (procent) => {
    let color = '#fff';
    let fontColor = '#fff';
    let devValue = parseFloat(procent).toFixed(2);

    if(devValue === "0.00"){
      color = '#dc1f1d';
      fontColor = "#ffffff";
    } else if (devValue < 5) {
      color = '#479a70';
      fontColor = "#ffffff";
    } else if (devValue > 5 && devValue <= 10) {
      color = '#d8b744';
      fontColor = "#000000";
    } else if (devValue > 10 && devValue <= 15) {
      color = '#da7124';
      fontColor = "#000";
    } else if (devValue > 15) {
      color = '#dc1f1d';
      fontColor = "#ffffff";
    }
    return {color, fontColor};
  }

  const tokenCreatorColor = (num_created_tokens_by_wallet) => {
    let color = '#fff';
    let fontColor = '#000';
    let created_tokens = num_created_tokens_by_wallet;

    if (created_tokens <= 1) {
      color = '#479a70';
      fontColor = '#fff';
    } else if (created_tokens >= 2 && created_tokens <= 3) {
      color = '#d8b744';
      fontColor = '#000';
    } else if (created_tokens > 3) {
      color = '#dc1f1d';
      fontColor = '#fff';
    }
    return { color, fontColor }
  }

  function sortAndGetTop10_2(arr) {
    const creatorAddress = arr.token.creator_address;
    const top10 = arr.data.holders_list
      .map(o => {
        o.creator = o.wallet_address === creatorAddress;
        return o;
      })
      .sort((a, b) => b.amount_percent - a.amount_percent)
      .slice(0, 3);
    return top10;
  };

  function formatNumberPrice(numberString) {
    let number = parseFloat(numberString);
    let roundedNumber = number.toFixed(2);
    let parts = roundedNumber.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  function aiMMdetector(wartosc) {
    const min = 10;
    const max = 50;
    if (wartosc < min) {
        return "0";
    }else if(wartosc > 50){
      return "100";
    }
    const procent = ((wartosc - min) / (max - min)) * 100;
    return `${Math.round(procent)}`;
  }

  return (
    <>
      <Toaster />
      <div className="modal-body token-modal">
        {tokenDetail ?
          (
            <div className='token-grid'>
              <div>
                <div className="card-title-row">
                  <div className="token-img" style={tokenDetail.token.has_image  ? {backgroundImage: `url(//api.scan.meme/images/256_${tokenDetail.token.contract_address}.png)`} : {}}></div>
                  <div className="token-info">

                    <div className="token-full-name-row">
                      <div className="title">
                        {tokenDetail.token.contract_name} (${tokenDetail.token.contract_short_name})                   
                      </div>
                      <div className="token-right">
                        <div className="token-label">Social media: </div>    
                        <div className="social-btns">

                          <button 
                            onClick={()=>handleRedirect(tokenDetail.token.twitter_url)}  
                            className={'table-btn'} 
                            style={{background:'transparent', padding: 0}}
                            data-tooltip-id="create_timestamp" data-tooltip-content={'Twitter'}
                            disabled={tokenDetail.token.twitter_url ? false : true}
                          >
                            <img src={tweeterSVG} alt=""/>
                          </button>

                          <button 
                            onClick={()=>handleRedirect(tokenDetail.token.website_url)} 
                            className={'table-btn'} 
                            style={{background:'transparent', padding: 0}}
                            data-tooltip-id="create_timestamp"
                            data-tooltip-content={'Website'}
                            disabled={tokenDetail.token.website_url ? false : true}
                          >
                            <img src={wwwSVG} alt=""/>
                          </button>

                          <button 
                            onClick={(e)=>{
                              handleRedirect(tokenDetail.token.telegram_url)
                            }}  
                            className={'table-btn'} 
                            style={{background:'transparent', padding: 0}}
                            data-tooltip-id="create_timestamp"
                            data-tooltip-content={'Telegram'}
                            disabled={tokenDetail.token.telegram_url ? false : true}
                          >
                            <img src={telegramSVG} alt=""/>
                          </button>

                        </div>                 
                      </div>

                    </div>

                    <div className='address-row'>
                      <div className="token-adress">
                        <div className="token-label">Contract:</div>
                        <div>{tokenDetail.token.contract_address}</div>
                        <div>
                          <button
                            className={'table-btn'} 
                            onClick={()=>copyToClipboard(tokenDetail.token.contract_address)}
                            style={{background:'transparent', padding: 0}}
                            data-tooltip-id="create_timestamp"
                            data-tooltip-content={'Copy Token Contract Address'}
                          >
                            <img src={copy} alt=""/>
                          </button>
                        </div>
                      </div>

                      <div className="token-adress">
                        <div className="token-label">Created by:</div>
                        <div className="token-value">
                          <button
                            className={'table-btn'} 
                            onClick={()=>copyToClipboard(tokenDetail.token.creator_address)}
                            style={{background:'transparent', padding: 0}}
                            data-tooltip-id="create_timestamp"
                            data-tooltip-content={'Copy Token Contract Address'}
                          >
                            {formatString(tokenDetail.token.creator_address)}
                          </button>
                        </div>
                        <div>
                          <button
                            className={'table-btn'} 
                            onClick={()=>copyToClipboard(tokenDetail.token.creator_address)}
                            style={{background:'transparent', padding: 0}}
                            data-tooltip-id="create_timestamp"
                            data-tooltip-content={'Copy Token Creator Address'}
                          >
                            <img src={copy} alt=""/>
                          </button>
                        </div>
                      </div>                      
                    </div>


                    <div style={{height: '100%'}}></div>

                    <div className="modal-row" style={{marginBottom: '5px'}}>
                      <div className="param">
                        <div className="token-label">Start</div>
                        <div className="token-value">{secondsToTime(tokenDetail.token.create_time_elapsed)}</div>
                      </div>
                      <div className="param">
                        <div className="token-label">Market cap</div>
                        <div className="token-value">{`$${(parseFloat(tokenDetail.data.market_cap_usd)/1000).toFixed(1)}k`}</div>
                      </div>
                      <div 
                        className="param"
                        style={{
                          backgroundColor: holderTopColor(addHolderParameter(tokenDetail).data.holder).color,
                          color: holderTopColor(addHolderParameter(tokenDetail).data.holder).fontColor
                        }}>
                        <div className="token-label">Top holder</div>
                        <div className="token-value">{addHolderParameter(tokenDetail).data.holder}%</div>
                      </div>
                      <div 
                        className="param"
                        style={{
                            background: tokenCreatorColor(tokenDetail.data.num_created_tokens_by_wallet).color,
                            color: tokenCreatorColor(tokenDetail.data.num_created_tokens_by_wallet).fontColor
                          }}>
                        <div className="token-label">Dev Crtd.</div>
                        <div className="token-value">{tokenDetail.data.num_created_tokens_by_wallet}</div>
                      </div>
                      <div className="param" style={{background: devColor(tokenDetail.data.owner.percent).color, color: devColor(tokenDetail.data.owner.percent).fontColor}}>
                        <div className="token-label">Dev</div>
                        <div className="token-value">{parseFloat(tokenDetail.data.owner.percent).toFixed(2)}%</div>
                      </div>
                    </div>

                    <div className="modal-row">
                      <div className="param">
                        <div className="token-label">Vol</div>
                        <div className="token-value">${(parseFloat(tokenDetail.data.volume_usd)/1000).toFixed(1)}k</div>
                      </div>
                      <div className="param">
                        <div className="token-label">Tx</div>
                        <div className="token-value">{tokenDetail.data.swaps}</div>
                      </div>
                      <div className="param">
                        <div className="token-label">Buy/Sell</div>
                        <div className="token-value">{(parseFloat(tokenDetail.data.buysell_last_5_minutes)/1000).toFixed(2)}k</div>
                      </div>
                      <div className="param">
                        <div className="token-label">Duplicates</div>
                        <div className="token-value">{tokenDetail.data.num_with_same_name}</div>
                      </div>
                      <div className="param">
                        <div className="token-label">Duplicates Bonding</div>
                        <div className="token-value">{tokenDetail.data.num_bonded_with_same_name}</div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="modal-cards-row">

                  {tokenDetail.data.prices &&
                    <div className="card-item">
                      <div className="card-item-col">
                        <div className="token-title">
                          <div className="token-icon">
                            <div style={tokenDetail.token.has_image  ? {backgroundImage: `url(//api.scan.meme/images/256_${tokenDetail.token.contract_address}.png)`} : {}}></div>
                          </div>
                          <div className="token-name">{tokenDetail.token.contract_name} (${tokenDetail.token.contract_short_name})</div>
                        </div>
                        <div className="token-price-chart">
                          <Chart chartData={transformArray(tokenDetail.data.prices)} height={302}/>
                        </div>
                      </div>
                    </div>            
                  }

                  <div>

                    <div className="card-item">
                      <div className="card-item-col">
                        <div className="card-item-row">


                          <div className="procent">
                            <div className="token-label">Bonding: </div>
                            <div className="token-value">{parseFloat(tokenDetail.data.bonding_progress).toFixed(1)}%</div>
                          </div> 

                        </div>
                        <div className="card-item-row">
                          {tokenDetail.token.launch_pending_event_time === null ?
                            (
                              <>
                                <div className="progres-bar">
                                  <div className="point" style={{left: `${Math.min((parseFloat(tokenDetail.data.bonding_progress)).toFixed(0), 100)}%`}}></div>
                                </div>                    
                              </>
                            )
                          :
                            (<div className="bonding-complete" style={{padding: '2px 10px', fontSize: '16px', textAlign: 'center', borderRadius: '5px'}}>
                              Bonding done: {moment(tokenDetail.token.launch_pending_event_time).format('mm')} min.
                            </div>)
                          }
                        </div>
                      </div>
                    </div>
                    
                    <div className="card-item">
                      <div className="card-item-col">
                        <div className="card-item-row">
                          <div>Holder</div>
                        </div>
                        <div className="card-item-row">
                          <TableContainer
                            data={sortAndGetTop10_2(addHolderParameter(tokenDetail))}
                            columns={columns_holders_list}
                          />
                        </div>
                      </div>
                    </div> 

                  </div>


                </div>

                <div className="card-item">
                  {(shouldShow === 'MASTER' || shouldShow === 'ADVANCED') &&
                    <div className='token-premium'>
                      <div className='premium-row'>
                        <div className='premium-item'>
                          <div className='item-label'>New</div>
                          <div className='item-value'>{(parseFloat(tokenDetail.data.wallets_stats.new)).toFixed(2)}%</div>
                        </div>
                        <div className='premium-item'>
                          <div className='item-label'>AI MM</div>
                          <div className='item-value'>{parseFloat(aiMMdetector(tokenDetail.data.MM.num_buys)).toFixed(2)}%</div>
                        </div>
                        <div className='premium-item'>
                          <div className='item-label'>Traders</div>
                          <div className='item-value'>{(parseFloat(tokenDetail.data.wallets_stats.user)).toFixed(2)}%</div>
                        </div>
                        <div className='premium-item'>
                          <div className='item-label'>Scammers</div>
                          <div className='item-value'>{(parseFloat(tokenDetail.data.wallets_stats.scam)).toFixed(2)}%</div>
                        </div>
                      </div>
                      {shouldShow === 'MASTER' &&
                        <div className='premium-row'>
                          <iframe
                            src={`https://app.insightx.network/bubblemaps/tron/${tokenDetail.token.contract_address}`}
                            width={'100%'}
                            height="300"
                            style={{borderRadius: '5px'}}
                          ></iframe>
                        </div>
                      }  
                    </div>
                  }
                </div>

              </div> 


                          
            </div>

          )
          : ''
        }
      </div>    
    </>

  );
};

export default TokenModalContent;
